import { Button, Container, Typography } from '@pwskills/rachnaui';
import Typewriter from 'typewriter-effect';
import React from 'react';
import Link from 'next/link';
import Statistics from './Statistics';
import { dataLayer } from '../../../utils/common/dataLayer.utils';

const Hero = () => {
  const sendDataLayerEvent = (type?: string, section?: string) => {
    dataLayer({
      event: 'clicks',
      click_text: type,
      section,
    });
  };

  return (
    <section className="py-8 large:!py-14">
      <Container>
        <div className="flex flex-col-reverse large:flex-row items-center justify-center large:!justify-between w-full gap-y-10 large:!gap-x-10">
          {/* 1st component */}
          <header className="flex flex-col items-center justify-center w-full large:!w-[58%] large:!text-left">
            <Typography
              variant="bold"
              component="h1"
              className="flex flex-col small:!flex-row  small:!self-start small:!justify-start medium:!justify-center regular:items-center regular:!flex-row regular:!justify-center large:!justify-start flex-wrap text-gray-850 large:!self-start font-inter w-full py-1"
            >
              Stay Ahead Of The Curve
            </Typography>
            <Typography
              variant="bold"
              component="h1"
              className="text-gray-850 self-center large:!self-start font-inter w-full py-1 !leading-[60px] small:flex small:flex-row medium:items-center medium:justify-center large:justify-start"
            >
              With Our &nbsp;
              <div className="flex large:!inline-flex text-orange-500 flex-wrap break-all justify-center large:!justify-start regular:!pt-0">
                <strong className="inline-block">{`<`}</strong>
                <Typewriter
                  options={{
                    strings: ['PRACTICAL', 'AFFORDABLE', 'EASY'],
                    autoStart: true,
                    loop: true,
                  }}
                />
                <strong className="inline-block">{`>`}</strong>
              </div>
            </Typography>
            <Typography
              variant="bold"
              component="h1"
              className="text-gray-850 self-start small:!self-start small:!justify-start medium:text-center large:!self-start large:!justify-start large:text-start font-inter w-full leading-[60px]"
            >
              Courses
            </Typography>

            <Typography
              variant="light"
              component="body-regular"
              className="text-gray-850 text-left medium:!text-center large:!text-left mt-4 large:!w-[90%] large:!self-start"
            >
              PW Skills is your one-stop-shop for upscaling. Get maximum value for time{' '}
              <br className="hidden regular:!block" />
              and resources you invest, with job-ready courses & high-technology,
              <br className="hidden regular:!block" /> available at the lowest cost.
            </Typography>
            <Link href="/courses?source=homepage_explore" className="self-start w-full mt-9">
              <Button
                variant="primary"
                className="mx-auto large:!mx-0 w-full px-[88px] py-[13px] medium:!px-12 large:!py-3 medium:!w-fit"
                onClick={() => sendDataLayerEvent('explore courses', 'homepage_hero')}
              >
                Explore Courses
              </Button>
            </Link>
          </header>
          {/* 2nd component */}
          <figure className="w-full regular:!w-[65%] large:!w-[42%] relative mx-auto">
            <img
              src="/images/homePage/heroBgImage.webp"
              alt="hero"
              className="!w-full aspect-square"
            />
            <img
              src="/images/homePage/hero-shadow.webp"
              alt="shadow"
              className="absolute top-28 z-2 right-10 "
            />
          </figure>
        </div>
        <Statistics />
      </Container>
    </section>
  );
};

export default Hero;
