import { ICompanyAndFaqsApi, ICourseCard, IWebBanner } from '@/interfaces/homePage';
import { baseApiUrl } from '@/utils/common/env.constants';
import { randomId } from '@pwskills/rachnaui/utils';
import { get, post } from './index';

class HomePageApi {
  static async getCompanyAndFaqs() {
    return get<ICompanyAndFaqsApi>(`/course/sitemeta?platformType=main&random_id=${randomId()}`);
  }

  static async tagFilter(data: { programType: string; showOnHomePage?: boolean }) {
    return post<ICourseCard>('/course/search?limit=10000&page=1&fullText=true', data);
  }

  static getWebBanner() {
    return get<{ data: IWebBanner[] }>('/banners?type=WEB', undefined, `${baseApiUrl}/v1`);
  }

  static getHomePageData(data?: any) {
    return post('/course/home', data);
  }
}

export default HomePageApi;
