import Head from 'next/head';
import Home from '@/components/HomePage';
// import useInitLogin from '@/hooks/useInitLogin';
import Loader from '@/components/common/Loader';
import useUserDetails from '@/store/userDetails';
import { phoneNumber } from '@/components/CourseDescriptionPageProfessional/CourseCurriculum/constants';
import RouteName from '../utils/route.util';
import TAGS from '../../public/headJson.json';
import HomePageAPI from '../api/homePage';
import Common from '../api/common';
import { IFaqsDetails } from '../interfaces/homePage';

const HomePage = ({ faqs, courses }: { faqs: IFaqsDetails[]; courses: any }) => {
  const structDataFaq = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqs?.map((faq: IFaqsDetails) => {
      return {
        '@type': 'Question',
        name: faq?.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq?.answer,
        },
      };
    }),
  };

  const structDataCarousel = {
    '@context': 'https://schema.org/',
    '@type': 'ItemList',
    itemListElement: courses.map((course: { slug: any; title: any }, index: number) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        name: course?.title,
        url: `https://pwskills.com/course/${course?.slug}`,
      };
    }),
  };

  const structData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    image: 'https://blog.pwskills.com/wp-content/uploads/2023/05/PWSkills-main-4.png',
    url: 'https://pwskills.com/',
    sameAs: ['https://www.linkedin.com/company/pw-skills-official/'],
    logo: 'https://blog.pwskills.com/wp-content/uploads/2023/05/PWSkills-main-4.png',
    name: 'PW Skills',
    description:
      'PW Skills is your one-stop-shop for upscaling. Get maximum value for time and resources you invest, with job-ready courses & high-technology available at the lowest cost.',
    email: 'support@pwskills.com',
    telephone: phoneNumber,
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Block B, Industrial Area, Sector 62',
      addressLocality: 'Noida',
      addressCountry: 'IN',
      addressRegion: 'UP',
      postalCode: '201309',
    },
  };

  // const { isLoading } = useInitLogin();
  const { profileLoader } = useUserDetails();
  return (
    <>
      <Head>
        {/* Primary Meta Tags */}
        <title>{TAGS.home.title}</title>
        <meta name="title" content={TAGS.home.title} />
        <meta name="description" content={TAGS.home.desc} />
        <link rel="canonical" href={RouteName.index} />

        {/* Facebook */}
        <meta property="og:url" content={RouteName.index} />
        <meta property="og:title" content={TAGS.home.title} />
        <meta property="og:description" content={TAGS.home.desc} />

        {/* Twitter */}
        <meta property="twitter:url" content={RouteName.index} />
        <meta property="twitter:title" content={TAGS.home.title} />
        <meta property="twitter:description" content={TAGS.home.desc} />
        <script
          key="homepage-faqs"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(structDataFaq) }}
        />
        <script
          key="homepage-carousel-itemlist"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(structDataCarousel) }}
        />
        <script
          key="homepage-local-business"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(structData) }}
        />
      </Head>
      {!profileLoader ? <Home /> : <Loader />}
    </>
  );
};

export const getStaticProps = async () => {
  const response = await HomePageAPI.getCompanyAndFaqs();
  const coursesRes = await Common.getAllCourses();
  let faqs: IFaqsDetails[] = [];
  let courses: any[] = [];
  if (response) {
    faqs = response?.data?.main?.faq;
  }
  if (coursesRes && coursesRes.data) {
    courses = coursesRes.data;
  }
  return {
    props: { faqs, courses },
    revalidate: 10,
  };
};

export default HomePage;
