import { Card, Typography } from '@pwskills/rachnaui';
import React from 'react';
import { statistics } from '@/utils/homePage/home.constants';

const Statistics = () => {
  return (
    <div className="flex flex-col medium:!flex-row items-center justify-center large:!justify-between gap-y-6 pt-8 large:pt-8 flex-wrap gap-x-5 w-full">
      {statistics?.map((item) => (
        <Card
          key={item?.id}
          className="flex items-center justify-start py-6 px-6 shadow-medium gap-x-4 w-full medium:!w-[48%] large:!w-[23%] !m-0 large:!self-start"
        >
          <img src={item.imgLink} alt="figure" className="w-12 h-12" />
          <div>
            <Typography component="h4" variant="bold" className="text-gray-850">
              {item.subTitle}
            </Typography>
            <Typography component="body-regular" variant="regular" className="text-gray-850">
              {item.title}
            </Typography>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default Statistics;
